export const mediaUploadGuidelinesSlides = [
  {
    path: '/assets/illustrations/media-upload-guideline-1.svg',
  },
  {
    path: '/assets/illustrations/media-upload-guideline-1.svg',
  },
  {
    path: '/assets/illustrations/media-upload-guideline-1.svg',
  },
  {
    path: '/assets/illustrations/media-upload-guideline-1.svg',
  },
];