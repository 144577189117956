
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CampaignWizardTitle"
})
export default class CampaignWizardTitle extends Vue {
  @Prop({ type: String }) title!: string;
  @Prop({ type: String }) subTitle!: string;
  toggleDesignTips = false;
}
