
import Upload from '@/shared-components/Upload.vue';
import { AdvertiserCampaign } from '@/types/campaigns';
import { MediaFile, MediaResolution } from '@/types/media';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Upload,
  },
})
export default class ChangeMedia extends Vue {
  @Prop({ type: Object, default: () => ({}) }) public campaign!: AdvertiserCampaign;
  @Prop({ type: Array, default: () => [] }) public mediaList!: MediaFile[];
  @Prop({ type: Boolean }) public isAuthenticated!: boolean;

  public acceptedFileTypes = ['jpg', 'jpeg', 'mp4'];

  public selectedMediaOption = 'UPLOAD_NOW';

  public get rejectionReason() {
    return this.campaign.rejectionReason;
  }

  public get rejectedBy() {
    return this.campaign.rejectedBy;
  }

  public get rejectedAt() {
    return this.campaign.rejectedAt;
  }

  public acceptedMediaResolution({
    width = 0,
    height = 0,
    unit = 'px',
  }: MediaFile): MediaResolution {
    return { width, height, unit };
  }

  @Emit('change')
  public handleMediaFileChanged(change: MediaFile, index: number) {
    const updatedMediaList = [...this.mediaList];
    Vue.set(updatedMediaList, index, change);

    return updatedMediaList;
  }

  public created() {
    this.selectedMediaOption =
      this.campaign.mediaList && this.campaign.mediaList.length
        ? 'UPLOAD_NOW'
        : 'UPLOAD_LATER';
  }
}
