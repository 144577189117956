
import { Component, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import { mediaUploadGuidelinesSlides } from '@/statics/media';
import { MediaFile } from '@/types/media';
import { nameFromPath } from '@/utils/files';

@Component({
  components: {
    Media,
  },
})
export default class MediaUploadGuidelines extends Vue {
  public slides = mediaUploadGuidelinesSlides;

  public mediaMetaData(path: string): MediaFile {
    return {
      name: nameFromPath(path),
      path,
    };
  }
}
