
import { MediaFile, MediaResolution } from '@/types/media';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Upload from '@/shared-components/Upload.vue';
import { LocationData } from '@/types/locations';
import { Nullable } from '@/types/misc';

@Component({
  components: {
    Upload,
  },
})
export default class UploadNow extends Vue {
  @Prop({ required: true }) locations!: LocationData[];
  @Prop({ type: String }) selectedMediaOption!: string;
  @Prop({ type: Array, default: () => [] }) selectedMediaFiles!: Nullable<
    MediaFile
  >[];
  @Prop({ required: true, type: Boolean }) isAuthenticated!: boolean;

  public valid = false;

  public acceptedMediaResolution({
    width = 0,
    height = 0,
    unit = 'px',
  }: MediaFile): MediaResolution {
    return { width, height, unit };
  }

  public handleMediaFileChanged(change: MediaFile | null, index: number) {
    Vue.set(this.selectedMediaFiles, index, change);
    this.$emit('update:selectedMediaFiles', this.selectedMediaFiles);
  }

  public acceptedFileTypes = ['jpg', 'jpeg', 'mp4'];
}
