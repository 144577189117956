import { FirebaseAppStorage } from '@/firebase/firebase-app';
import { getCurrentUser } from '@/firebase/firebase-user';
import firebaseNames from '@/statics/firebase-names';
import { getFileExtension } from '@/utils/media';

/**
 * Create ref fora file to be uploaded
 * @param file Media file
 *
 * @param mediaId uuid for the media
 */
export const createFileRef = async (file: File, mediaId: string) => {
  const { name: fileName } = file;
  const fileExtension = getFileExtension(fileName);
  const uniqueFileName = `${ mediaId }.${ fileExtension }_${ mediaId }`;
  const currentUser = await getCurrentUser();
  return FirebaseAppStorage.ref(firebaseNames.MEDIA_FILES.VAL)
    .child(currentUser!.uid)
    .child(firebaseNames.MEDIA_FILES.FOR_CAMPAIGNS)
    .child(uniqueFileName);
};
